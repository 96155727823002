<template>
  <section class="bg_color">
    <h2>利用規約</h2>
    <section class="inner p0">
      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">目的</span>
          </div>
        </h4>
        <p class="box_txt-list">
          本利用規約は、MOG × Supported by
          BR蒲郡（以下、「当サイト」といいます。）をご利用になる場合の規約を定めます。<br /><br />
          当サイトをご利用頂く場合、必ず本利用規約に同意頂く必要があります。<br />
          ご応募いただいた場合には、本利用規約で明示されている諸条件すべてにご同意いただいたものとさせていただきます。なお、本利用規約の内容は、事前通知又は承諾なしに変更することがあります。予めご了承下さい。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">サービス提供</span>
          </div>
        </h4>
        <p class="box_txt-list">
          当サイトは、蒲郡市ボートレース事業部（以下「サービス提供者」といいます。）が提供するサービスです。当サイトは、本利用規約に同意した上で、所定の手続に従って参加登録を行った方（以下「参加者」といいます。）が参加することのできる期間限定のサービス（以下「本サービス」といいます。）です。<br />
          本サービスを利用することによって、本利用規約の内容をご承諾いただいたものとみなします。<br /><br />
          サービス提供者は、当サイトにおいて、ボートレース蒲郡主催のレースに関する情報等を提供します。<br />
          サービス提供者は、必要に応じて当サイトの内容を事前の告知なく追加し、変更し、または削除することがあります。<br />
          サービス提供者は、インターネットにより当サイトを提供します。<br />
          参加者及び当サイトを利用される方（以下「お客様」といいます。）から当サイトについてのメール等での問い合わせは、日本語によるものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">ご利用料金</span>
          </div>
        </h4>
        <p class="box_txt-list">
          料金は無料です。ただし当サイトを利用する際の通信費はお客様のご負担となります。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">参加条件について</span>
          </div>
        </h4>
        <ul class="box_txt-list">
          <li>・２０歳以上の方</li>
          <li>・日本国内にお住まいの方（国籍は問いません）</li>
          <li>・営業性・事業性個人、法人の方はご登録いただけません</li>
          <li>
            ・未成年、本サービスを活用した営利目的の方はご登録いただけません
          </li>
        </ul>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">当サイトの中断・停止・終了</span>
          </div>
        </h4>
        <p class="box_txt-list">
          サービス提供者は、システムの保守の必要性がある場合や天災地変、通信混雑、通信障害、コンピュータ・サーバ障害その他やむを得ない事由が発生した場合、またはサービス提供者の都合により、お客様に事前の連絡をすることなく、本サービスを中断・停止・終了することがあります。<br /><br />
          当サイトは、ボートレースオフィシャルウェブサイト（boatrace.jp）から出されるデータを元に計算されております。ボートレース蒲郡本場、ボートレース蒲郡オフィシャルウェブサイトとは発信される情報が異なる場合があります。また、ボートレースオフィシャルウェブサイト（boatrace.jp）の運用状況により、当サイトに影響が及ぶ場合があります。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">当サイトの利用手続き</span>
          </div>
        </h4>
        <p class="box_txt-list">
          お客様は、当サイトを利用する場合は所定の手続きを取るものとし、退会する場合も同様とします。退会された場合、獲得モグーは失効します。なお、退会の手続きがなされない限り当サイトを利用しているものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">サービス提供地域</span>
          </div>
        </h4>
        <p class="box_txt-list">
          当サイトにおけるサービスの提供地域については、日本国内に限るものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">情報の利用目的</span>
          </div>
        </h4>
        <p class="box_txt-list">
          参加者から取得する個人情報については、以下の目的で使用します。
        </p>
        <ul class="box_txt-list">
          <li>・本サービスの提供</li>
          <li>・賞品発送</li>
          <li>・連絡</li>
        </ul>
        <p class="box_txt-list">
          ※これらの目的以外で利用する場合には、参加者に対し事前に確認または同意を求めます。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">情報の利用範囲</span>
          </div>
        </h4>
        <p class="box_txt-list">
          お客様は、本サービスを通じて入手した一切の情報等を、営利を目的とするか否かを問わず複製、改変、翻案、放送、インターネット、編集、出版、上映、配布、販売するなどいかなる方法によっても、使用させたり、公表させたりすることはできません。加えて、お客様は第三者をして同様のことはできません。また、お客様はニックネームと登録モーター情報が当サイト上で掲載されることを承諾しているものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">免責</span>
          </div>
        </h4>
        <p class="box_txt-list">
          サービス提供者は、本サービスの提供ができなかったこと、本サービスによる情報等の提供が遅れたこと、本サービスにより提供した情報等に誤りがあったこと、その他本サービスの利用により生じたお客様あるいは第三者の損害に対し、いかなる責任も負いません。<br /><br />
          獲得モグーの計算方法に不備があった場合、速やかにプログラムを修正します。ただし、それに伴う順位変動についてはご了承ください。<br /><br />
          <span class="txt-red"
            >追加斡旋やモーターの故障による変更など、前検日のモーター抽選結果に登録の無いモーターが何らかの理由で出走していても選択は出来ません。また、既にご登録をされているモーターが追加出走した場合においてもポイントは加算されません。</span
          >
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">禁止行為および利用停止</span>
          </div>
        </h4>
        <p class="box_txt-list">
          お客様は、本サービスを利用するにあたり、次に該当する行為を行わないものとします。<br /><br />
          （１）当サイトのシステム・データ・情報提供内容を改ざん、損壊する行為<br />
          （２）有害なコンピュータープログラム等を送信または書き込む行為<br />
          （３）パスワードの不正使用、譲渡、貸与、名義変更又は売買する等の行為<br />
          （４）第三者に本サービスを利用させる行為<br />
          （５）第三者または当サイトの著作権、その他の知的財産権を侵害する行為、または侵害するおそれのある行為<br />
          （６）第三者または当サイトの財産、名誉、プライバシー等を侵害する行為、または侵害するおそれのある行為<br />
          （７）他の利用者もしくは第三者を誹謗中傷し、名誉を傷つける行為<br />
          （８）本サービスの利用または提供を妨げる行為<br />
          （９）法令または公序良俗に反する行為<br />
          （１０）同一人物と判断される複数アカウント作成行為<br />
          （１１）その他、サービス提供者が不適当と判断する行為<br /><br />
          当サイトは、お客様が上記のいずれかに該当する行為をなした場合には、当該お客様へ事前の通告または催告することなく、本サービスの利用を停止することができるものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">損害賠償</span>
          </div>
        </h4>
        <p class="box_txt-list">
          お客様が本サービスを通じてまたは本サービスに関して、当サイトに損害を与えた場合、サービス提供者はその者に対し、損害賠償の請求を行うことができるものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">情報の消去</span>
          </div>
        </h4>
        <p class="box_txt-list">
          サービス提供者は、本サービス運営の必要上、一定期間経過あるいは所定の量を越えた場合、本サービスに蓄積された、文書、プログラム、データ、電子メール等を消去することがあります。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon"
              >利用手続きに関する個人情報登録について</span
            >
          </div>
        </h4>
        <p class="box_txt-list">
          お客様は本サービスを利用するための所定の手続きにおいて次の個人情報を入力または登録することを、同意するものとします。<br /><br />
          サービス利用のご登録<br />
          （1） メールアドレス<br />
          （2） 電話投票加入者番号<br /><br />
          賞品当選者<br />
          （3） 氏名<br />
          （4） 郵便番号<br />
          （5） 住所<br />
          （6） 電話番号<br />
          （7） 生年月日<br />
          （8） 性別<br /><br />
          <span class="txt-red"
            >なお、ご登録後個人情報の変更があった場合、早急に<router-link
              :to="{ name: 'Inquiry' }"
              >こちら</router-link
            >にご連絡いただきますようお願いします。</span
          >
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">お客様による個人情報管理について</span>
          </div>
        </h4>
        <p class="box_txt-list">
          登録されたメールアドレスやパスワードはご自身で管理ください。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">準拠法</span>
          </div>
        </h4>
        <p class="box_txt-list">
          本利用規約および本サービス利用契約の成立、効力、履行および解釈に関しては、日本法が適用されるものとします。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">権利等の譲渡の禁止</span>
          </div>
        </h4>
        <p class="box_txt-list">
          <span class="txt-red"
            >参加者は本サービスに関する契約上の地位・権利または義務を第三者に譲渡し、承継し、引き受けさせ、または担保に供することはできません。</span
          >
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">裁判管轄</span>
          </div>
        </h4>
        <p class="box_txt-list">
          <span class="txt-red"
            >本サイトまたは本サービスに関する一切の紛争については、名古屋地方裁判所または名古屋簡易裁判所を第一審の専属的合意管轄裁判所とします。</span
          >
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon"><span class="txt-red">附則</span></span>
          </div>
        </h4>
        <p class="box_txt-list">
          <span class="txt-red"
            >(1)この規約は、令和4年4月1日から実施します。<br />(2)この規約は、令和6年4月30日から実施します。</span
          >
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">特典・景品等の受け取りについて</span>
          </div>
        </h4>
        <p class="box_txt-list">
          お客様に特典、景品等を発送する際、ご登録住所の不明、不備、また受取遅滞・受取拒否などによって特典、景品をお届けできなかった場合、該当参加者はその受け取る権利を喪失します。<br />
          当選者に送られる送付先情報記入フォームに期限内にご入力いただけない場合も受け取る権利を喪失します。<br />
          また、特典、景品を受け取る権利について、譲渡、貸与、名義変更、担保供与すること、その他第三者の利用に供する行為は禁じます。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">利用規約の変更</span>
          </div>
        </h4>
        <p class="box_txt-list">
          本利用規約は予告なく変更される場合がございます。変更された場合は、変更後の内容がお客様に適用になります。
        </p>
        <h4 class="ttl_lst">
          <div class="inner tac no_icon">
            <span class="moter_icon">プライバシーポリシー</span>
          </div>
        </h4>
        <p class="box_txt-list">
          登録された個人情報については、ボートレース蒲郡のプライバシーポリシーに基づき管理いたします。<br />
          <a
            href="https://www.gamagori-kyotei.com/00privacy/00privacy.htm"
            target="_blank"
            >https://www.gamagori-kyotei.com/00privacy/00privacy.htm</a
          >
        </p>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "TermsOfService",
  mounted: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
<style lang="css" src="@/assets/css/style_select.css" scoped></style>
